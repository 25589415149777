//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================
// Component: Variables
// ========================================================================

$base-font-size: 16;

// $noto-sans: 'Noto Sans Display';

// Colors
$brand-primary: #000000;
$secondary-orange: #fa8600;
$background: #F0F0EB;
$color-offwhite: #FAFAF5;
$color-white: #FFFFFF;
$color-black: #000000;
$red: #ED3024;
$teal: #00A499;
$shadow: #fff;

// Forms
$form-field-border-radius:23px;
$form-field-background:$color-white;
$form-field-border:1px solid #ddd;
$form-field-font-size:17px;
$form-field-color: $shadow;
$form-field-padding:0 20px;
$form-field-height:44px;

// Layout
$wrapper-max-width-large:1440px;
$wrapper-max-width-small:1200px;
$breakpoints: sm 568px 568px,
  md 768px 768px,
  lg 960px 960px,
  xlg 1200px 1200px !default;
$breakpoint-sm: 568px;
$breakpoint-md: 768px;
$breakpoint-lg: 960px;
$breakpoint-xlg: 1200px;
$flexboxgrid-max-width: $wrapper-max-width-large !default;
