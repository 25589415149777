//
// Name:            Navigation
//
// Description:     Contains all navigation styles
//
// ========================================================================
// Component: Navigation
// ========================================================================

.topnav {
	@include font(10, 2, auto);

	display: inline-block;

	margin-left: 20px;

	vertical-align: top;
	text-transform: uppercase;

	a {
		color: $color-white;
		font-weight: bold;
	}

	>ul {
		list-style: none;
		padding: 0;

		>li {
			display: inline-block;
			position: relative;
			width: auto;
			margin: 0 20px;
			vertical-align: top;

			>a {
				display: inline-block;
				&:after {
					content: "";
					display: block;
					width: 0;
					left: 0;
					height: 2px;
					background-color: $color-white;
					transition: .5s;
				}
				&:hover {
					&:after {
						width: 100%;
					}
				}
			}
			ul.submenu {
				position: absolute;
				top: 30px;
				left: 0;
				z-index: 999;
				width: 300px;
				list-style: none;
				padding: 0;
				border-top: 3px $secondary-orange solid;
				background-color: $color-offwhite;
				max-height: 0;
				overflow: hidden;
				transform: perspective(400) rotate3d(1, 0, 0, -90deg);
				transform-origin: 50% 0;
				transition: 350ms;
				li {
					width: 100%;
					a {
						display: inline-block;
						width: 100%;
						padding: 10px;
						color: $brand-primary;
						line-height: 1.2;
						&:hover {}
					}
				}
			}
			&:hover .submenu {
				max-height: 1000px;
				transform: perspective(400) rotate3d(0, 0, 0, 0);
			}
		}
	}
}

.ie .topnav>ul>li ul.submenu,
.edge .topnav>ul>li ul.submenu {
	display: none;
}

.ie .topnav>ul>li:hover .submenu,
.edge .topnav>ul>li:hover .submenu {
	display: block;
}

@media only screen and (max-width: 980px) and (min-width: 1px) {
	.header .button {
		display: none;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.topnav {
		display: block;
		position: fixed;
		top: 95px;
		left: 0;
		width: 100%;
		z-index: 999;
		margin-left: 0;
		background-color: $secondary-orange;
		font-size: 16px;
		width: calc(100% + 40px);
		transform:translate3d(100%, 0, 0);
		transition:.7s;

		&.open {
			transform:translate3d(0, 0, 0);
		}
		>ul {
			padding: 0 30px;
			>li {
				display: inline-block;
				width: 100%;
				margin: 0;
				height: auto;
				>a {
					width: 100%;
					text-align: center;
					line-height: 64px;
					border-bottom: 1px $color-black solid;
					line-height: 40px;
					&:after {
						display: none;
					}
				}
				&:last-child a {
					border-bottom: none;
				}
				ul.submenu {
					top: 0;
					width: 100%;
					position: relative;
					li {
						border-bottom: none;
					}
				}
			}
			.parent a {}
		}
	}
}
