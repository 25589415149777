//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//
// ========================================================================
// Component
// ========================================================================

html {
	font-size: $base-font-size + px;
	line-height: rem(30);
	font-family: 'Din', sans-serif;
	-webkit-font-smoothing: antialiased;
}

body {
	background: $background;
	color: $brand-primary;
	overflow-x: hidden;

	&.fixed {
		overflow:hidden;
	}
}

p > a {
	color: $brand-primary;
	text-decoration: underline;

	&:hover,
	&:focus {
		color: $secondary-orange;
		text-decoration: underline;
	}
}

video::-webkit-media-controls {
	display: none !important;
}

// Screen reader text
.sr-only {
	position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Heading styles
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

h1 {
	@include font(72, auto, 94);

	@media only screen and (max-width: 768px) {
		@include font(48, auto, 72);
	}

	@media only screen and (max-width: 568px) {
		@include font(36, auto, 44);
	}
}

h2 {
	@include font(72, auto, 94);

	@media only screen and (max-width: 768px) {
		@include font(48, auto, 72);
	}

	@media only screen and (max-width: 568px) {
		@include font(36, auto, 44);
	}
}

h3 {
	@include font(36, auto, 44);

	@media only screen and (max-width: 568px) {
		@include font(24, auto, 32);
	}
}

h4 {
	@include font(16, auto, 30);
}

// Global text styles
p.subtitle {
	@include font(16, auto, auto);
	font-weight: bold;
	font-weight: bold;
	margin-bottom: 0;
}

blockquote {
	@include font(24, auto, 41);
	font-style: italic;
	margin: 28px 0;
	padding: 0 40px;
	border-left: 2px #ccc solid;
}

.intro-text {
	@include font(20, auto, 34);
	margin-bottom: 20px;
}
