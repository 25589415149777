body {
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #161B6A;
	line-height: normal;
	font-style: normal;

}

.wrapper {
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.deco-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw; 
	height: 100%;
}
.game-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	text-align: center;
	margin-top: 25px;
	width: 70vw;

	@include breakpoint(tablet) {
		margin: 25px;
	}
	.logo-container {
		width: 100px;
		height: 100px;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	p {
		font-size: 40px;
		font-weight: 900;
		color: $color-white;

		@include breakpoint(laptop) {
			font-size: 24px;
		}

		@include breakpoint(tablet) {
			font-size: 18px;
		}

		span.nowrap {
			@include breakpoint(tablet) {
				text-wrap: nowrap;
			}
		}

		span.accent {
			color: #E8F51B;
		}
	}
}

.game-container {
	position: relative;
	width: 80%;
	max-width: 1440px;
	display: grid;
	margin: 50px 0;
	z-index: 2;

	&:has(.reveal) {
		.memory-game {
			margin-top: 100px;

			@include breakpoint(laptop) {
				aspect-ratio: 3 / 2.3;
			}
		}
	}
	.memory-game {
		width: 100%;
		aspect-ratio: 9 / 5;
		margin-top: 20px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		perspective: 1000px;
		border-radius: 5px;
		background-image: url("../images/hero.jpg");
		background-position: center;
		background-size: cover;
		transition: all 1s ease-in 1s;

		@include breakpoint(laptop) {
			grid-template-columns: 1fr 1fr 1fr;
			aspect-ratio: 3 / 4;
		}
	}

	.reveal-element {
		display: flex;
		position: absolute;
		justify-self: center;

		margin: 0 auto;
		opacity: 0;
		margin-top: 18px;
		width: 40%;
		max-width: 800px;
		aspect-ratio: 3 / 1;
		background-image: url("../images/reveal.svg");
		background-size: contain;
		background-repeat: no-repeat;

		z-index: -1;
		transition: all 1s 2s;

		@include breakpoint(tablet) {
			width: 60%;
			font-size: 24px;
			left: 22%;
		}

		&.reveal {
			opacity: 1;
			transform: scale(1);
			transform-origin: center;
			z-index: 999;
		}
	}

	.memory-card {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transform-style: preserve-3d;
		transition:
			transform 0.5s,
			opacity 0.5s 5s;
		border: 2px solid #0f2d51;
		width: 100%;
		height: 100%;

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 103%;
			height: 103%;
			z-index: -1;
			background-color: #292E8C;
			transition: all 0.5s ease-out;
			border: 2px solid #0f2d51;
		}

		p {
			position: absolute;
			z-index: 999;
			font-size: 24px;
		}

		&:hover {
			transform: scale(1.05);
			transition: transform 0.25s ease-in-out;
			z-index: 999;

			&:after {
				transform: scale(1.05);
				transition: transform 0.25s ease-in-out;
			}
		}

		&:active {
			transform: scale(1.05);
			transition: transform 0.2s ease-in;
		}

		&.flip {
			transform: rotateY(180deg);
			border: 0px solid #eae6e0;
			z-index: 999;

			&::after {
				display: none;
				height: 100%;
				width: 100%;
			}
		}

		&.disappear {
			box-shadow: unset;
			border: unset;
			.front-face {
				opacity: 0;
			}
			&:after {
				display: none;
			}
		}
	}

	.front-face,
	.back-face {
		width: 100%;
		height: 100%;
		position: absolute;
		backface-visibility: hidden;
		object-fit: cover;

		&:focus-visible {
			border: 2px solid white;
		}
	}


	.front-face {
		object-fit: cover;
		transform: rotateY(180deg);
		transition: opacity 0.5s 1s;
	}
}

.hide {
	position: absolute;
	display: flex;
	justify-content: center;
	z-index: -1;
	opacity: 0;
	margin-top: 500px;
	margin-inline: auto;
	height: 0;
	transition: all 1s ease-in 1s;
	&.reveal {
		position: relative;
		z-index: 0;
		opacity: 1;
		height: 150px;
		margin-top: 28px;

		.details {
			font-size: 24px;
			font-weight: 400;
			line-height: 32px;
			width: 50%;
			text-align: center;
			color: $color-white;

			@include breakpoint(tablet) {
				width: 100%;
			}

			.link {
				color: #1e4b92;
				font-weight: 700;
			}
		}

		.cta {
			width: 200px;
			position: absolute;
			top: -150px;
			right: -50px;

			@include breakpoint(tablet) {
				width: 150px;
			}

			@include breakpoint(mobile) {
				top: -100px;
				right: -25px;
				width: 100px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}
