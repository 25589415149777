//
// Name:            Buttons
//
// Description:     Contains all button styles
//
// ========================================================================
// Component
// ========================================================================
button {
	display: flex;
	appearance: none;
	background-color: unset;
	border: none;
	margin: unset;
	padding: unset;
	text-align: unset;
	padding-block: unset;
	padding-inline: unset;
}
