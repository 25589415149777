#hero {
	position:relative;

	width:100%;
	height:calc(100vh - 95px);
	min-height: 500px;

	background: $brand-primary;
  color: $color-white;

	background-repeat: no-repeat;
	background-size: cover;

	p {
		@include font(20, auto, 30);
	}

	.wrapper {
		position: relative;
	}

	.row {
		max-width: 36em;
	}

	.banner {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;

		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.banner-overlay {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color: rgba($color-black, .5);

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to top, rgba($color-black, 0) 0%, $color-black);
			opacity: 0.62;
			transition: 0.3s;
		}
	}

	.swiper-container {
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.swiper-button-next, .swiper-button-prev {
			background: none;
			border: none;
		}
	}
}
