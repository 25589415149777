//
// Name:            Footer
//
// Description:     Contains all footer styles
//
// ========================================================================
// Component: Footer
// =========================================================================

.footer {
	@include font(13, auto, 16);

	position: relative;
	width: 100%;
	padding: 20px 0;
	background-color: rgba(white, 0.84);
	overflow: hidden;

	.row {
		display: flex;
		justify-content: space-between;

		@media only screen and (max-width: $breakpoint-lg) and (min-width: 1px) {
			flex-flow: row wrap;
			justify-content: center;
			text-align: center;
		}
	}

	.nav {
		@media only screen and (max-width: $breakpoint-lg) and (min-width: 1px) {
			flex: 0 0 100%;
			max-width: 100%;

			padding: 20px 0;
		}

		ul {
			@include list-reset;
			display: flex;

			@media only screen and (max-width: $breakpoint-lg) and (min-width: 1px) {
				flex-flow: column nowrap;
			}

			li {
				margin: 0 0.5em;

				@media only screen and (max-width: $breakpoint-lg) and (min-width: 1px) {
					margin: 0.5em;
				}

				a {
					text-decoration: underline;

					&:hover,
					&:focus {
						color: $secondary-orange;
					}
				}
			}
		}
	}

	.copyright {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.logo {
			display: block;
			width: 157px;
			height: 70px;

			margin-right: 15px;

			background-image: url('../images/logo.svg');
			background-size: 100%;
			background-repeat: no-repeat;
			overflow: hidden;
			text-indent: -300px;
		}

		p {
			margin-top: 0;
		}

	}
}
