//
// Name:            Header
//
// Description:     Contains all header styles
//
// ========================================================================
// Component: Header
// ========================================================================
header.header {
	position: relative;
	top:0;
	left:0;

	width: 100%;
	height: 95px;

	background: $secondary-orange;
	color: $color-white;

	z-index:9;

	&.sticky {
		position:sticky;
	}
	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		height: 95px;

		margin-top: 0;
	}

	.logo {
		display: inline-block;
		width: 157px;
		height: 70px;
		margin: 0;
		overflow: hidden;

		a {
			display: inline-block;
			width: 157px;
			height: 70px;
			text-indent: -9999px;
			background-image: url('../images/logo.svg');
			background-size: 100%;
			background-repeat: no-repeat;
		}
	}

	.hamburger {
		@include button-reset;

		display: none;
		padding: 10px;
		cursor: pointer;

		&:hover {
			.hamburger-box {
				&:before,
				&:after {
					background-color: $color-black;
				}
			}

			.hamburger-inner {
				background-color: $color-black;
			}
		}

		&-box {
			display: block;
			position: relative;
			width: 35px;
			height: 25px;

			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 0;

				width: 100%;
				height: 4px;

				background-color: $color-white;

				transform: translateY(0) rotate(0deg);
				transform-origin: center;
				transition: transform .3s ease, top .3s ease;
			}

			&:before {
				top: 0;
			}

			&:after {
				top: calc(100% - 4px);
			}
		}

		&-inner {
			@include vertical-center;
			left: 0;

			width: 100%;
			height: 4px;

			background-color: $color-white;
		}

		&.is-active {
			.hamburger-box {
				&:before {
					top: 50%;
					transform: translateY(-50%) rotate(45deg);
				}

				&:after {
					top: 50%;
					transform: translateY(-50%) rotate(-45deg);
				}
			}

			.hamburger-inner {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	header.header {
		.row {
			margin: 0;
		}
		.hamburger {
			display: block;
		}
		.row>[class*=col-]:first-child {
			margin-top: 0;
		}
	}
}

@media only screen and (max-width: 568px) and (min-width: 1px) {
	header.header {
		.logo {
			a {}
		}
		.hamburger {
			padding-right: 10px;
			&:after {
				display: none;
			}
		}
	}
}
